import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import getYoutubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { PortableText } from "@portabletext/react";
import SpotifyPlayer from 'react-spotify-player';
import SEO from "../components/SEO";

const BlogContainer = styled.div`
    padding-bottom: 10rem;
`

const BlogPostContainer = styled.div`
    padding: 2rem;
    width: 60%;
    margin: auto;

    h1 {
        padding-top: 3rem;
    }

    blockquote {
        border-left: 5px solid #393939;
        padding: 10px;
    }

    .youtube, .SpotifyPlayer {
        display: block;
        margin: auto;
    }

    @media (max-width: 768px) {
        width: 80%;
        padding: 0;
    }
`
const ButtonsContainer = styled.div`
    width: 90%;
    margin: auto;
    position: relative;
    a {
        text-decoration: underline;
    }
    .previous {
        position: absolute;
        left: 0;
    }
    .next {
        position: absolute;
        right: 0;
    }

    @media (max-width: 768px) {
        .previous, .next {
            position: static;
            text-align: center;
            display: block;
            margin-bottom: 30px;
        }
    }
`

const portableTextComponents = {
    types: {
        youtube: ({ value }) => {
            const { url } = value;
            const id = getYoutubeId(url);
            return <YouTube className="youtube" videoId={id} />
        },
        spotify: ({ value }) => {
            const { url } = value;
            return <SpotifyPlayer uri={url}/>
        }
    }
}

export default function BlogPostPage({data, pageContext}) {
    let preview = data.post._rawBody.filter(body => body._type === "block")


    return (
        <BlogContainer>
             <SEO
                title={data.post.title}
                description={preview[0].children[0].text}
                keywords={[`J. Marcelo Borromeo`, `Filipino writer`, `UEA`, `novel`, `short story`, `fiction`, `non-fiction`]}
                />
            <BlogPostContainer>
                <h1>{data.post.title} </h1>
                <PortableText value={data.post._rawBody} components={portableTextComponents} />
            </BlogPostContainer>
            <ButtonsContainer>
                {
                    pageContext.previous && <Link className="previous" to={`../${pageContext.previous.slug.current}`}>{`← ${pageContext.previous.title}`}</Link>
                }
                {
                    pageContext.next && <Link className="next" to={`../${pageContext.next.slug.current}`}>{`${pageContext.next.title}  →`}</Link>
                }
            </ButtonsContainer>
        </BlogContainer>
    )
}

export const query = graphql`
    query($slug: String!) {
        post: sanityPost (slug: {current: { eq: $slug}}) {
            id
            title
            publishedAt
            _rawBody
        }
    }
`